/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ResponsiveImage from '../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

import { ifExternalUrl } from '../NUp/helper';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles((theme) => ({
    fourUpImage: {
        aspectRatio: '1/1',
        borderRadius: '50%',
        border: '1px solid rgb(213, 213, 213)',
        width: '63%',
        padding: '10px',
        [theme.breakpoints.down(1200)]: {
            width: '55%',
            padding: '7px',
        },
        [theme.breakpoints.down(1025)]: {
            padding: '3px',
        },
    },
    link: {
        color: theme.palette?.ctaButton || '#2F2F2F',
        textDecoration: 'none',
    },
}));

const SideBySide4UpImagePanel = ({ data, imageData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        link = {}, tracking_event_category = '',
        tracking_event_action = '', tracking_event_label = '',
    } = data || {};

    return (
        <div className={classes.imageContainer}>
            <Link
                data-ga-category={tracking_event_category || ''}
                data-ga-action={tracking_event_category || ''}
                data-ga-label={tracking_event_label || ''}
                className={`${classes.link}`}
                onClick={(e) => {
                    dispatch(trackEvent({
                        eventCategory: tracking_event_category || '',
                        eventAction: tracking_event_action || '',
                        eventLabel: tracking_event_label || '',
                    }));
                    ifExternalUrl(e, link.href, true); // this breaks in unit tests and doesn't work on right click, open in new tab
                }}
                to={link.href}
                title={link.title}
                data-testid="side-image-anchor"
            >
                <ResponsiveImage path={imageData?.url} alt={imageData?.description || 'Category Image'} className={classes.fourUpImage} />
            </Link>
        </div>
    );
};

SideBySide4UpImagePanel.propTypes = {
    data: shape({
        tracking_event_category: string,
        tracking_event_action: string,
        tracking_event_label: string,
    }),
    imageData: shape({
        title: string,
        url: string,
    }).isRequired,

};
SideBySide4UpImagePanel.defaultProps = {
    data: {},
};

export default SideBySide4UpImagePanel;
