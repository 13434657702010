/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useUIDSeed } from 'react-uid';
import {
    shape, string, arrayOf,
} from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CTA from '../Buttons/CTA';
import { parseFont } from '../../../helpers/common/helper';
import SideBySide4UpLeftPanel from './SideBySide4UpLeftPanel';
import SideBySide4UpImagePanel from './SideBySide4UpImagePanel';

const useStyles = makeStyles((theme) => ({
    mainSBSContainer: {
        border: `1px solid ${(theme.palette.text?.mainSBSContainer || '#AFAFAF')}`,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        maxWidth: '1400px',
        width: '100%',
        margin: '0 auto',
        gap: '1em',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.down(1025)]: {
            justifyItems: 'normal',
            alignItems: 'normal',
        },
        [theme.breakpoints.down(600)]: {
            display: 'block',
            border: 'none',
        },
    },
    fourUpImage: {
        borderRadius: '50%',
        width: '50%',
        [theme.breakpoints.down(1200)]: {
            width: '45%',
        },
        [theme.breakpoints.down(1025)]: {
            width: '70%',
        },
    },
    fourUpContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        rowGap: '1.2em',
        [theme.breakpoints.down(600)]: {
            padding: '0px',
            display: 'block',
            border: `1px solid ${(theme.palette.text?.mainFourUpTitle || '#D5D5D5')}`,
            borderBottom: 'none',
        },
    },
    mainFourUpContainer: {
        flexBasis: '50%',
        textAlign: 'center',
    },
    SideBySide4Up: {
        padding: 'clamp(5px,3vw,10px)',
    },
    imgCtaButton: (fontFamily) => ({
        '& div': {
            fontFamily: `${parseFont(fontFamily.fourUpFont) || 'Crimson, Garamond, Times, serif'}`,
            border: 'none',
            color: theme.palette?.ctaButton || '#2F2F2F',
            fontWeight: '700',
            width: '100%',
            background: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1rem',
            padding: '4px 0px',
            letterSpacing: '0.02em',
            lineHeight: '20px',
            '& svg': {
                width: ' 0.7em',
                [theme.breakpoints.down(769)]: {
                    position: 'absolute',
                    right: '-9px',
                },
                [theme.breakpoints.down(768)]: {
                    right: '-8px',
                },
                [theme.breakpoints.down(600)]: {
                    position: 'initial',
                },
            },
            [theme.breakpoints.down(1024)]: {
                padding: '4px 0px .5em 0px',
            },
            [theme.breakpoints.down(769)]: {
                width: '90%',
                margin: '0 auto',
                position: 'relative',
                padding: '4px 0px 1.5em',
            },
            [theme.breakpoints.down(600)]: {
                padding: '4px 0',
            },
        },
    }),
    rightFourUpContainer: {
        [theme.breakpoints.down(1025)]: {
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
        },
        [theme.breakpoints.down(600)]: {
            display: 'block',
        },
    },
    ctaButtonMobile: (fontFamily) => ({
        '& div': {
            fontFamily: `${parseFont(fontFamily.fourUpFont) || 'Crimson, Garamond, Times, serif'}`,
            border: 'none',
            color: theme.palette?.ctaButton || '#2F2F2F',
            fontWeight: '700',
            width: '100%',
            background: 'none',
            fontSize: '0.875rem',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: 'normal',
        },
    }),
    ctaButton: (fontFamily) => ({
        '& div': {
            fontFamily: `${parseFont(fontFamily.fourUpFont) || 'Crimson, Garamond, Times, serif'}`,
            border: 'none',
            color: theme.palette?.ctaButton || '#2F2F2F',
            fontWeight: '700',
            width: '100%',
            background: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down(900)]: {
                paddingTop: '0px',
                fontSize: '1rem',
            },
            [theme.breakpoints.down(600)]: {
                paddingTop: '8px',
                borderBottom: `1px solid ${(theme.palette.text?.mainFourUpTitle || '#D5D5D5')}`,
                '& svg': {
                    marginLeft: '-2px',
                    marginRight: '0px',
                    width: '0.8em',
                },
            },
        },
    }),

    mainFourUpTitle: (fontFamily) => ({
        fontFamily: `${parseFont(fontFamily.fourUpFont) || 'Crimson, Garamond, Times, serif'}`,
        border: 'none',
        width: '90%',
        fontSize: '1rem',
        margin: '0 auto',
        padding: '0',
        [theme.breakpoints.down(1400)]: {
            padding: '0px',
        },
        [theme.breakpoints.down(900)]: {
            fontSize: '0.688rem',
        },
        '& a': {
            textDecoration: 'none',
            color: theme.palette?.ctaButton || '#2F2F2F',
            fontWeight: 700,
            fontStyle: 'normal',
            letterSpacing: '0.02em',
            [theme.breakpoints.down(900)]: {
                fontSize: '0.813rem',
                letterSpacing: 'normal',
            },
            [theme.breakpoints.down(767)]: {
                fontSize: '0.7rem',
            },
            [theme.breakpoints.down(600)]: {
                fontSize: '1.1rem',
            },
        },
        [theme.breakpoints.down(600)]: {
            textAlign: 'center',
            padding: '4px',
            borderBottom: `1px solid ${(theme.palette.text?.mainFourUpTitle || '#D5D5D5')}`,
            width: '100%',
        },
    }),
}));

const SideBySide4Up = ({ data }) => {
    const seed = useUIDSeed();
    const isMobile = useMediaQuery('(max-width: 599.5px)');
    const isTablet = useMediaQuery('(max-width: 1024px)');

    if (!data?.reference?.[0]) return null;

    const mainData = data?.reference?.[0];
    const mainPresentation = mainData?.main_presentation;
    const mainCtaData = mainPresentation?.cta || {};
    const fontFamily = {
        mainHeadingFont: mainData?.styling?.heading_font,
        fourUpFont: mainData?.styling?.four_up_title_font,
        mainCtaFont: mainData?.styling?.main_cta_font,
    };
    const classes = useStyles(fontFamily);

    return (
        <div className={classes.SideBySide4Up}>
            <div className={classes.mainSBSContainer}>
                <SideBySide4UpLeftPanel
                    isMobile={isMobile}
                    isTablet={isTablet}
                    mainPresentation={mainPresentation}
                    styling={mainData?.styling}
                />
                <div className={classes.rightFourUpContainer}>
                    <div className={classes.fourUpContainer}>
                        {isMobile && mainCtaData.link?.href && (
                            <CTA
                                className={`${classes.ctaButton}`}
                                copy={mainCtaData.link?.title}
                                href={mainCtaData.link.href}
                                title={mainCtaData.link?.title}
                                tracking_event_category={mainCtaData?.tracking_event_category}
                                tracking_event_action={mainCtaData?.tracking_event_action}
                                tracking_event_label={mainCtaData?.tracking_event_label}
                            />
                        )}
                        {mainData?.four_up?.group?.map((item, key) => {
                            const {
                                link = {}, tracking_event_category = '',
                                tracking_event_action = '', tracking_event_label = '',
                            } = item.linking || {};
                            return (
                                <div key={seed(item)} className={classes.mainFourUpContainer}>
                                    {!isMobile && (
                                        <SideBySide4UpImagePanel
                                            data={item.linking}
                                            imageData={item?.image}
                                        />
                                    )}
                                    <div className={classes.mainFourUpTitle} data-testid={`SidebySide4Up_${key}`}>
                                        <CTA
                                            className={classes.imgCtaButton}
                                            copy={item.title}
                                            href={link.href}
                                            title={link.title}
                                            tracking_event_category={tracking_event_category}
                                            tracking_event_action={tracking_event_action}
                                            tracking_event_label={tracking_event_label}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

SideBySide4Up.propTypes = {
    data: shape({
        reference: arrayOf(shape({
            four_up: shape({
                group: arrayOf(shape({
                    title: string,
                    linking: shape({
                        link: shape({
                            href: string,
                            title: string,
                        }),
                    }),
                    image: shape({
                        url: string,
                        title: string,
                    }),
                })),
            }),
            main_presentation: shape({
                cta: shape({
                    href: string,
                    title: string,
                }),
            }),
            styling: shape({
                four_up_title_font: string,
                heading_font: string,
            }),
        })),
    }),

};
SideBySide4Up.defaultProps = {
    data: {},
};

export default SideBySide4Up;
