/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string, bool,
} from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CTA from '../Buttons/CTA';
import { parseFont } from '../../../helpers/common/helper';
import { ifExternalUrl } from '../NUp/helper';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles((theme) => ({
    desktopImage: {
        width: '100%',
        display: 'block',
        [theme.breakpoints.down(900)]: {
            height: '100%',
        },
    },
    mainTitle: (fontFamily) => ({
        fontFamily: `${parseFont(fontFamily.mainHeadingFont) || 'Crimson, Garamond, Times, serif'}`,
        fontSize: '2.875rem',
        lineHeight: '58px',
        fontWeight: '400',
        alignItems: 'center',
        display: 'flex',
        margin: '0px',
        letterSpacing: '0.01em',
        justifyContent: 'center',
        [theme.breakpoints.down(1025)]: {
            width: '395px',
        },
        [theme.breakpoints.down(769)]: {
            lineHeight: '40px',
            fontSize: '2rem',
            width: '275px',
        },
        [theme.breakpoints.down(600)]: {
            lineHeight: '40px',
            fontSize: '2rem',
            width: '90%',
        },
    }),

    ctaButton: (fontFamily) => ({
        fontSize: '1.25rem',
        lineHeight: '28px',
        letterSpacing: '0.01em',
        fontFamily: `${parseFont(fontFamily.mainCtaFont) || 'Crimson, Garamond, Times, serif'}`,
        border: 'none',
        color: theme.palette?.ctaButton || '#2F2F2F',
        fontWeight: '700',
        width: '100%',
        background: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down(769)]: {
            paddingTop: '5px',
            fontSize: '1rem',
            lineHeight: '20px',
        },
        [theme.breakpoints.down(600)]: {
            paddingTop: '8px',
            borderBottom: `1px solid ${(theme.palette.text?.mainFourUpTitle || '#D5D5D5')}`,
            '& svg': {
                marginLeft: '-2px',
                marginRight: '0px',
                width: '0.8em',
            },
        },
    }),
    leftMainTittle: (fontFamily) => ({
        fontFamily: `${parseFont(fontFamily.mainHeadingFont) || 'Crimson, Garamond, Times, serif'}`,
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        top: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down(1025)]: {
            top: '12px',
        },
        [theme.breakpoints.down(769)]: {
            top: '10px',
        },
    }),
    link: {
        color: theme.palette?.ctaButton || '#2F2F2F',
        textDecoration: 'none',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    mainContainer: (fontFamily) => ({
        position: 'relative',
        fontFamily: `${parseFont(fontFamily.mainHeadingFont) || 'Crimson, Garamond, Times, serif'}`,
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down(600)]: {
            minHeight: ' 70px',
            border: `1px solid ${(theme.palette.text?.mainSBSContainer || '#AFAFAF')}`,
            borderBottom: 'none',
        },
    }),
}));

const SideBySide4UpLeftPanel = ({
    mainPresentation, styling, isMobile, isTablet,
}) => {
    const {
        link = {}, tracking_event_category = '',
        tracking_event_action = '', tracking_event_label = '',
    } = mainPresentation?.cta || {};

    const fontFamily = {
        mainHeadingFont: styling?.heading_font,
        mainCtaFont: styling?.main_cta_font,
    };
    const dispatch = useDispatch();
    const classes = useStyles(fontFamily);

    const renderLeftImage = (imageURL, alt) => (
        <img src={imageURL} alt={alt} className={classes.desktopImage} />
    );

    const showingImages = () => {
        if (isMobile) {
            return renderLeftImage(mainPresentation?.mobile?.url, mainPresentation?.mobile?.description || 'Category Image');
        } if (isTablet) {
            return renderLeftImage(mainPresentation?.tablet?.url || mainPresentation?.desktop?.url, mainPresentation?.desktop?.description || 'Category Image');
        }
        return renderLeftImage(mainPresentation?.desktop?.url, mainPresentation?.desktop?.description || 'Category Image');
    };

    return (
        link?.href
            ? (
                <Link
                    data-ga-category={tracking_event_category || ''}
                    data-ga-action={tracking_event_action || ''}
                    data-ga-label={tracking_event_label || ''}
                    className={`${classes.link}`}
                    onClick={(e) => {
                        dispatch(trackEvent({
                            eventCategory: tracking_event_category || '',
                            eventAction: tracking_event_action || '',
                            eventLabel: tracking_event_label || '',
                        }));
                        ifExternalUrl(e, link.href, true); // this breaks in unit tests and doesn't work on right click, open in new tab
                    }}
                    to={link.href}
                    title={link.title}
                    data-testid="side-nup-anchor"
                >
                    {mainPresentation?.heading && (
                        <div className={classes.leftMainTittle}>
                            <h2 className={classes.mainTitle}>{mainPresentation.heading}</h2>
                            {!isMobile && (
                                <CTA
                                    className={`${classes.ctaButton}`}
                                    copy={link?.title}
                                    title={link?.title}
                                />
                            )}
                        </div>
                    )}
                    {showingImages()}
                </Link>
            ) : mainPresentation?.heading && (
            <div className={`${classes.mainContainer}`}>
                <h2 className={classes.mainTitle}>{mainPresentation.heading}</h2>
            </div>
        )
    );
};

SideBySide4UpLeftPanel.propTypes = {
    mainPresentation: shape({
        heading: string,
        cta: shape({
            href: string,
            title: string,
        }),
        desktop: shape({
            url: string,
            title: string,
        }),
        mobile: shape({
            url: string,
            title: string,
        }),
        tablet: shape({
            url: string,
            title: string,
        }),
    }),
    styling: shape({
        heading_font: string,
        main_cta_font: string,
    }),
    isMobile: bool.isRequired,
    isTablet: bool.isRequired,
};
SideBySide4UpLeftPanel.defaultProps = {
    mainPresentation: {},
    styling: {},
};

export default SideBySide4UpLeftPanel;
